<template>
    <div id="PerfilAdmin">
        <div class="container">
            <div class="row">
                <div class="col-12 div-editar-perfil">
                    <div class="titulo-tabela">
                        <h2>Meu Perfil</h2>
                        <p>*Campos obrigatórios</p>
                    </div>	
                    <v-form ref="form_edita_usuario" class="row">
                        <div class="col-12">
                            <label class="primary-text-field-label">Nome*</label>
                            <v-text-field
                                class="primary-text-field"
                                :rules="[v => !!v || 'Campo obrigatório']" 
                                v-model="dados_usuario.nome"
                                label="Nome"                                        
                                solo
                                hide-details
                            />
                        </div>
                        <div class="col-md-6 col-12">
                            <label class="primary-text-field-label">E-mail*</label>
                            <v-text-field
                                class="primary-text-field"
                                :rules="[v => !!v || 'Campo obrigatório']" 
                                v-model="dados_usuario.email"
                                label="E-mail"                                        
                                solo
                                hide-details
                            />
                        </div>
                        <div class="col-md-6 col-12">
                            <label class="primary-text-field-label">Senha*</label>
                            <v-text-field
                                class="primary-text-field"
                                type="password"
                                v-model="dados_usuario.senha"
                                label="Senha"
                                solo
                                hide-details
                            />
                        </div>
                        <div class="col-md-6 col-12">
                            <label class="primary-text-field-label">Telefone*</label>
                            <v-text-field
                                class="primary-text-field"
                                :rules="[v => !!v || 'Campo obrigatório']" 
                                v-model="dados_usuario.telefone"
                                label="Telefone"                                        
                                solo
                                hide-details
                                v-mask="'(##) #####-####'"
                            />
                        </div>
                        <div class="col-md-6 col-12">
                            <label class="primary-text-field-label">CPF*</label>
                            <v-text-field
                                class="primary-text-field"
                                :rules="[v => !!v || 'Campo obrigatório']" 
                                v-model="dados_usuario.cpf"
                                label="CPF"                                        
                                solo
                                hide-details
                                v-mask="'###.###.###-##'"
                            />
                        </div>
                        <!-- <div class="col-md-4 col-12">
                            <label class="primary-text-field-label">CEP*</label>
                            <v-text-field
                                class="primary-text-field"
                                :rules="[v => !!v || 'Campo obrigatório']" 
                                v-model="dados_usuario.enderecos[0].cep"
                                label="CEP"
                                solo
                                hide-details
                                v-mask="'#####-###'"
                            />
                        </div>
                        <div class="col-md-6 col-12">
                            <label class="primary-text-field-label">Endereço*</label>
                            <v-text-field
                                class="primary-text-field"
                                :rules="[v => !!v || 'Campo obrigatório']" 
                                v-model="dados_usuario.enderecos[0].logradouro"
                                label="Endereço"
                                solo
                                hide-details
                            />
                        </div>
                        <div class="col-md-2 col-12">
                            <label class="primary-text-field-label">Nº*</label>
                            <v-text-field
                                class="primary-text-field"
                                :rules="[v => !!v || 'Campo obrigatório']" 
                                v-model="dados_usuario.enderecos[0].numero"
                                label="Nº"
                                solo
                                hide-details
                                @change="buscaEstados()"
                            />
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <label class="primary-text-field-label">Estado*</label>
                            <v-select
                                class="primary-select"
                                :items="estados"
                                item-text="nome" 
                                item-value="codigo"
                                v-model="dados_usuario.enderecos[0].uf_id" 
                                label="Estado"
                                solo
                                hide-details
                                @change="buscaCidades()"
                            />
                        </div>
                        <div class="col-md-4 col-12">
                            <label class="primary-text-field-label">Cidade*</label>
                            <v-select
                                class="primary-select"
                                :items="cidades"
                                item-text="nome" 
                                item-value="codigo"
                                v-model="dados_usuario.enderecos[0].cidade_id" 
                                label="Cidade"
                                solo
                                hide-details
                            />
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                            <label class="primary-text-field-label">Bairro*</label>
                            <v-text-field
                                class="primary-text-field"
                                :rules="[v => !!v || 'Campo obrigatório']" 
                                v-model="dados_usuario.enderecos[0].bairro"
                                label="Bairro"
                                solo
                                hide-details
                            />
                        </div>
                        <div class="col-md-4 col-12">
                            <label class="primary-text-field-label">Complemento</label>
                            <v-text-field
                                class="primary-text-field"
                                v-model="dados_usuario.enderecos[0].complemento"
                                label="Complemento"
                                solo
                                hide-details
                            />
                        </div>
                        <div class="col-md-8 col-12">
                            <label class="primary-text-field-label">Ponto de Referência</label>
                            <v-text-field
                                class="primary-text-field"
                                v-model="dados_usuario.enderecos[0].ponto_referencia"
                                label="Ponto de Referência"
                                solo
                                hide-details
                            />
                        </div> -->
                    </v-form>
                </div>
                <div class="col-12 div-bottom">
                    <v-btn class="primary-button" large @click="editaUsuario()">
                        Salvar
                    </v-btn>
                </div>
            </div>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script>
    import store from '@/store'
    import IconeUsuario from '@/assets/images/REDIRECTUSUARIO.png'
    import IconeSair from '@/assets/images/SAIR.png'
    import IconeHome from '@/assets/images/HOMEICON.png'
    import Loader from '@/components/Loader.vue'

    export default {
        name: 'PerfilAdmin',
        components: {
			Loader,
		},
        data: () => ({
            icone_Usuario: IconeUsuario,
            icone_Sair: IconeSair,
            icone_Home: IconeHome,
            loading: false,
            dados_usuario: '',
            cliente: {
                codigo: '',
                nome: '',
                cpf: '',
                email: '',
                senha: null,
                telefone: '',
                // enderecos: [{
                //     cep: '',
                //     logradouro: '',
                //     numero: '',
                //     uf_id: '',
                //     cidade_id: '',
                //     bairro: '',
                //     complemento: '',
                //     ponto_referencia: '',
                // }],
            },
            estados: [],
            cidades: []
        }),
        methods: {
            async init(){
                await this.buscaEstados()
                this.dados_usuario  = store.getters.user
                this.dados_usuario.codigo = store.getters.user.id
                this.dados_usuario.nome = store.getters.user.nome
                this.dados_usuario.cpf = store.getters.user.cpf
                this.dados_usuario.email = store.getters.user.email
                this.dados_usuario.telefone = store.getters.user.telefone
                // this.dados_usuario.enderecos[0].cep = store.getters.user.enderecos[0].cep
                // this.dados_usuario.enderecos[0].logradouro = store.getters.user.enderecos[0].logradouro
                // this.dados_usuario.enderecos[0].numero = store.getters.user.enderecos[0].numero
                // this.dados_usuario.enderecos[0].uf_id = store.getters.user.enderecos[0].codigo_estado
                // this.dados_usuario.enderecos[0].cidade_id = store.getters.user.enderecos[0].codigo_cidade
                // await this.buscaCidades(this.dados_usuario.enderecos[0].uf_id)
                // this.dados_usuario.enderecos[0].bairro = store.getters.user.enderecos[0].bairro
                // this.dados_usuario.enderecos[0].complemento = store.getters.user.enderecos[0].complemento
                // this.dados_usuario.enderecos[0].ponto_referencia = store.getters.user.enderecos[0].ponto_referencia
            },
            async editaUsuario(){
                let temp_usuario = {
                    codigo: this.dados_usuario.codigo,
                    nome: this.dados_usuario.nome,
                    cpf: this.dados_usuario.cpf,
                    email: this.dados_usuario.email,
                    telefone: this.dados_usuario.telefone,
                }
                
				if (this.$refs.form_edita_usuario.validate()) {
                    if (this.dados_usuario.senha) {
                        temp_usuario.senha = this.dados_usuario.senha
                    }
                    this.loading = await true
                    var resp = await store.dispatch('salvaCliente', temp_usuario)
                    if (resp.sucesso) {
                        this.$swal({
                            title: 'Atenção',
                            icon:'success',
                            text: 'Usuário editado com sucesso!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                        // document.location.reload(true);
                    }else{
                        this.$swal({
                            title: 'Atenção',
                            icon:'error',
                            text: 'Não foi possível concluir as alterações!',
                            showCancelButton: false,
                            showCloseButton: false,
                            showConfirmButton: true,
                            confirmButtonText:'Ok'
                        })
                        this.loading = await false
                    }
				}
			},
            async buscaEstados(){
				var resp = await store.dispatch('getEstado');
				this.estados = resp
			},
			async buscaCidades(){
				var resp = await store.dispatch('getCidade', this.dados_usuario.enderecos[0].uf_id)
				this.cidades = resp
			},
        },
        async mounted(){
            await this.init()
        }
    }
</script>

<style lang="scss">
    #PerfilAdmin{
        position: relative;
        .titulo-tabela{
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-family: 'Poppins';
            h2{
                font-style: normal;
                font-weight: 500;
                font-size: 25px;
                line-height: 28px;
                color: #fff;
                border-bottom: 4px solid #FE8204;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 10px 0px;
                width: max-content;
            }
            p{
                font-family: 'Poppins';
				font-size: 16px;
				color: #fff;
                font-weight: 500;
                text-align: right;
    			width: 100%;
                margin-bottom: 0;
			}
        }
        .div-editar-perfil{
            form{
                font-family: 'Poppins';
                font-weight: 500;
                padding: 18px 0;
                h2{					
                    font-style: normal;
                    font-weight: 500;
                    font-size: 22px;
                    line-height: 36px;
                    color: #fff;
                }
                label{
                    color: #fff;
                }
                .v-input__slot{
                    border-radius: 100px!important;
                    padding-left: 15px!important;
                    input{
                        padding: 10px;
                        font-weight: 500;
                    }
                }
                @media (max-width: 1400px){
                    padding: 0 15px;
                }
            }
        }
        .div-bottom{
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .primary-button{
                background-color: #FE8204 !important;
                color: #fff;
                font-weight: 500;
                font-size: 18px;
                padding: 15px 20px;
            }
            @media (max-width: 1400px){
                padding: 0 27px;
            }
        }
    }
</style>